























































import Vue from "vue";
import { mapGetters } from "vuex";
import ReportService from "@/api/report-service";

export default Vue.extend({
    name: "ReassignReviewerModal",
    props: {
        report: { type: Object, required: false, default: () => ({}) },
    },
    data() {
        return {
            isLoading: false,
            reviewerId: "",
            search: "",
            showSelectionTray: false,
        };
    },
    computed: {
        ...mapGetters({
            users: "users/allUsers",
        }),
        reviewers(): any {
            if (this.users) return this.users.filter((user: any) => user.roles.includes("Reviewer")).filter((user: any) => user.fullName.toLowerCase().includes(this.search.toLowerCase()));
            return [];
        },
        selectedReviewer(): any {
            return this.reviewers.find((reviewer: any) => reviewer.id === this.reviewerId);
        },
        isStillTheSameReviewer(): boolean {
            return this.reviewerId === this.report.currentDeskUser.id;
        },
    },
    mounted() {
        this.reviewerId = this.report.currentDeskUser?.id;
    },
    watch: {
        report(newValue) {
            if (newValue) {
                this.reviewerId = newValue.currentDeskUser?.id;
            }
        },
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("modal-reassign-reviewer");
        },

        async reassignReviewer() {
            this.isLoading = true;
            try {
                const response = await ReportService.reassignReviewer(this.report.id, { newReviewerId: this.reviewerId });
                if (response && response.status === 200) {
                    this.$bvToast.toast(`Report have been reassigned to ${this.selectedReviewer.fullName} successfully`, { solid: true, variant: "success", title: "Success" });
                    this.$emit("change-reviewer", { id: this.report.id, currentDeskUser: { ...this.selectedReviewer } });
                    this.$bvModal.hide("modal-reassign-reviewer");
                }
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
            }
        },

        selectReviewer(reviewerId: string) {
            this.reviewerId = reviewerId;
            this.showSelectionTray = false;
        },
    },
});
